.dimmer {
  background-color: black;
  background-attachment: fixed;
  background-color: black;
  position: fixed;
  z-index: -50;
  height:100%;
  width:100%;
  top:0;
  opacity:0.7
}
.background {
  background-color: black;
  font-family: 'Comfortaa';
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-color: black;
  position: fixed;
  z-index: -100;
  height:100%;
  width:100%;
}

.hide-posts-btn {
  float: right;
}

img {
  width: 120px;
}

.display-container {
  text-align: center;
  margin: auto;
  width: 90%;
  padding-top: 20%;
}

.clock-container {
  height:136px;
}

#clock {
  font-size: 50px;
  margin: auto;
  text-align: center;
  color: antiquewhite;
  text-shadow: 3px 3px black;
  font-family: 'Comfortaa';
}

.hidden {
  display: none
}

a {
  overflow-wrap: break-word;
}

.hover-show:hover{
  opacity: 0.2;
}

